




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CHANGE_FINANCES } from '../../../store/actions';
import { ChangeFinances, ConservatorshipDetails, FinancialState } from '../../../types';
import { ApiResponse } from '@/components/types';
import DatePicker from '@/components/form/DatePicker.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker
  }
})
export default class FinancialChangeDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CHANGE_FINANCES) updateFinances!: (params: ChangeFinances) => Promise<ApiResponse>;

  WEALTHY: FinancialState = 'wealthy';
  DESTITUTE: FinancialState = 'destitute';

  dialog: boolean = false;
  error: any = null;

  value: ChangeFinances = {
    conservatorship_id: this.conservatorship.id,
    financial_state: this.newState(this.conservatorship.conservatee.financialState),
    changed: format(Date.now(), 'yyyy-MM-dd'),
  };

  newState(financialState: FinancialState) {
    if (financialState === this.WEALTHY) {
      return this.DESTITUTE;
    }

    return this.WEALTHY;
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservatorship_id: this.conservatorship.id,
        financial_state: this.newState(this.conservatorship.conservatee.financialState),
        changed: format(Date.now(), 'yyyy-MM-dd'),
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.updateFinances(this.value);
  }
}
